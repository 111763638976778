:root {
  --f-s-sm: 1.35rem;
  --f-s-xs: .75rem;
  --f-s-root-fluid: calc(1rem + .25 * (100vw - 20rem) / 28);
  --f-s-xl-fluid: calc(2.1875rem + 1.5625 * (100vw - 20rem) / 76);
  --f-s-lg-fluid: calc(1.75rem + .5 * (100vw - 20rem) / 76);
  --f-s-md-fluid: calc(1.35rem + .15 * (100vw - 20rem) / 76);
  --f-s-root: clamp(1rem, var(--f-s-root-fluid), 1.25rem);
  --f-s-xl: clamp(2.1875rem, var(--f-s-xl-fluid), 3.75rem);
  --f-s-lg: clamp(1.75rem, var(--f-s-lg-fluid), 2.25rem);
  --f-s-md: clamp(1.35rem, var(--f-s-md-fluid), 1.5rem);
}

@supports not (font-size: clamp(1px, 1vw, 2px)) {
  :root {
    --f-s-root: 1rem;
    --f-s-xl: 2.1875rem;
    --f-s-lg: 1.75rem;
    --f-s-md: 1.35rem;
  }

  @media (min-width: 20rem) and (max-width: 48rem) {
    :root {
      --f-s-root: var(--f-s-root-fluid);
    }
  }

  @media (min-width: 20rem) and (max-width: 120rem) {
    :root {
      --f-s-xl: var(--f-s-xl-fluid);
      --f-s-lg: var(--f-s-lg-fluid);
      --f-s-md: var(--f-s-md-fluid);
    }
  }

  @media (min-width: 48rem) {
    :root {
      --f-s-root: 1.25rem;
    }
  }

  @media (min-width: 120rem) {
    :root {
      --f-s-xl: 3.75rem;
      --f-s-lg: 2.25rem;
      --f-s-md: 1.5rem;
    }
  }
}

:root {
  --c-off-white: #f2f2f4;
  --c-goma-grey: #3c3c3c;
  --c-white: #fff;
  --c-mid-grey: #5f5f5f;
  --c-map-blue: #65677f;
  --c-goma-grey-hover: #5c5c5c;
  --c-goma-grey-selected: #2a2a2c;
  --c-goma-grey-disabled: #d5dadf;
  --c-caption-dark: var(--c-goma-grey);
  --c-caption-light: var(--c-white);
  --c-form-error: #f95252;
  --g-col-size: 6rem;
  --g-col: minmax(min(var(--g-col-size), 100%), 1fr);
  --g-gap: 1.5rem;
  --container-width: 93rem;
  --gutter: calc((100vw - var(--container-width)) / 2);
  --slope: clamp(1rem, min(5vw, 12vh), 5rem);
  --button-bg: var(--c-goma-grey);
  --button-fg: var(--c-white);
  --selection-bg: var(--c-goma-grey);
  --selection-fg: var(--c-white);
}

.stories {
  --font-size-md: 1.2em;
  --font-size-lg: 1.5em;
  --font-size-xl: 1.75em;
}

:where(.stories > *) {
  margin: 0;
}

.stories time:not(.card *) {
  font-size: 1.2em;
}

.stories time:not(.card *):after {
  content: "";
  margin: var(--g-gap) auto;
  width: var(--g-gap);
  border-top: .2em solid;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
}

.stories iframe {
  border: none;
}

.stories iframe[src*="artlines"] {
  aspect-ratio: 1340 / 768;
  height: 57.3134cqw;
  max-height: 75vh;
  max-height: 75cqh;
  max-width: 100%;
  width: auto;
  margin: auto;
}

@container (orientation: portrait) and (max-width: 1200px) {
  .stories iframe[src*="artlines"] {
    aspect-ratio: 548 / 640;
    height: calc(115cqw - var(--g-gap) * 2);
  }
}

.stories > :not(div, header, footer, section, figure, blockquote, iframe):has( + :not(div, header, footer, section, figure, blockquote, iframe, :where(h1, h2, h3, h4, h5, h6) + figure)), .stories > :where(h1, h2, h3, h4, h5, h6, figure, blockquote, iframe):has( + figure) {
  margin-bottom: calc(var(--g-gap) * -1);
}

@container (min-width: 54em) {
  .stories {
    --column-offset: 2;
    --column-offset-figure: max(1, calc(var(--column-offset)  - 1));
  }

  .stories > :not(div, header, footer, section, figure, iframe) {
    grid-column: col-start var(--column-offset) / col-end calc(var(--column-offset) * -1);
  }

  .stories > figure {
    grid-column: col-start var(--column-offset-figure) / col-end calc(var(--column-offset-figure) * -1);
  }
}

@container (min-width: 70em) {
  .stories {
    --column-offset: 3;
  }
}

@container (min-width: 85em) {
  .stories {
    --column-offset: 4;
  }
}

.stories > header {
  text-align: center;
}

:where(.stories > *):where(p:first-of-type) {
  font-size: var(--font-size-md);
}

:where(.stories > *):where(h1, h2, h3, h4, h5, h6) {
  text-wrap: pretty;
  font-weight: 600;
  line-height: 1.3;
}

:where(.stories > *):where(h1, h2, h3, h4) {
  font-size: var(--font-size-md);
}

:where(.stories:not(:has(h2 ~ h3)):has(h1 ~ h2) > h1) {
  font-size: var(--font-size-lg);
}

h1:where(.stories:has(h1 ~ h2 ~ h3) > *) {
  font-size: var(--font-size-xl);
  font-weight: 700;
}

h2:where(.stories:has(h1 ~ h2 ~ h3) > *) {
  font-size: var(--font-size-lg);
}

h4:where(.stories:has(h1 ~ h2 ~ h3 ~ h4 ~ h5) > *) {
  font-weight: 500;
}

h6:where(.stories:has(h1 ~ h2 ~ h3 ~ h4 ~ h5) > *) {
  color: #777;
}

:where(.stories > footer > :not(aside)) {
  grid-column: col-start var(--column-offset, 1) / col-end calc(var(--column-offset, 1) * -1);
}

.stories figcaption {
  text-align: left;
}

.stories blockquote {
  text-align: center;
  max-width: 90%;
  margin: auto;
  font-family: serif;
}

.stories > figure {
  margin-left: auto;
  margin-right: auto;
}

.stories > figure picture:before {
  content: "";
  border: 1px solid #3c3c3c1a;
  border: 1px solid color-mix(in srgb, var(--c-goma-grey), transparent 90%);
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.stories-search > header {
  text-align: center;
}

.stories-search > header > :where(h1, h2, h3, h4, h5, h6) {
  margin: 0;
}

.stories-search > header > h2 {
  margin: calc(var(--g-gap) * -1.2) 0 var(--g-gap);
  font-weight: 300;
}

.stories-search .card__image {
  aspect-ratio: 1;
}

dialog.modal {
  --container-width: 76.8rem;
}

.modal .breadcrumbs {
  display: none;
}

.modal :where(.breadcrumbs + h1) {
  margin: 0;
}

.modal iframe {
  margin: auto;
}

.card__image picture {
  aspect-ratio: 1;
}

.card__image:where(a[href*="youtube"] *) img {
  height: 100px;
  width: auto;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  z-index: 2;
  margin: auto;
  position: relative;
  aspect-ratio: 16 / 9 !important;
}

.card__image:where(a[href*="youtube"] *) picture {
  padding: 1.25em;
  display: grid;
  background-size: 190% !important;
}

.card__image:where(a[href*="youtube"] *) picture:before, .card__image:where(a[href*="youtube"] *) picture:after {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.card__image:where(a[href*="youtube"] *) picture:before {
  content: "";
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: #00000080;
}

.card__image:where(a[href*="youtube"] *) picture:after {
  content: url("data:image/svg+xml,%3Csvg width='68' height='47' viewBox='0 0 68 47' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M66.1 7.33913C65.7181 5.91637 64.9699 4.61914 63.9303 3.57734C62.8907 2.53554 61.5962 1.78573 60.1765 1.40301C54.9515 2.09173e-06 34 0 34 0C34 0 13.0485 2.09173e-06 7.82352 1.40301C6.40379 1.78574 5.10932 2.53554 4.06973 3.57734C3.03014 4.61914 2.28193 5.91637 1.90003 7.33913C0.499997 12.5753 0.5 23.5 0.5 23.5C0.5 23.5 0.499997 34.4247 1.90003 39.6609C2.28193 41.0836 3.03014 42.3809 4.06973 43.4227C5.10932 44.4645 6.40379 45.2143 7.82352 45.597C13.0485 47 34 47 34 47C34 47 54.9515 47 60.1765 45.597C61.5962 45.2143 62.8907 44.4645 63.9303 43.4227C64.9699 42.3809 65.7181 41.0836 66.1 39.6609C67.5 34.4247 67.5 23.5 67.5 23.5C67.5 23.5 67.5 12.5753 66.1 7.33913Z' fill='%23D81F1E'/%3E%3Cpath d='M27.5 33L44.5 23.0003L27.5 13V33Z' fill='white'/%3E%3C/svg%3E%0A");
  z-index: 2;
  place-content: center;
  margin: auto;
  display: grid;
}

/*# sourceMappingURL=stories.31751d93.css.map */
