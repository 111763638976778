@use './_resource.scss';
@use './_search.scss';

.stories {
  @include resource.styles;
  
  &-search {
    @include search.styles;
  }
}

.card__image {
  picture {
    aspect-ratio: 1;
  }

  &:where(a[href*=youtube] *) {
    img {
      aspect-ratio: 16/9 !important;
      height: 100px;
      width: auto;
      height: auto;
      max-width: 100%;
      object-fit: cover;
      z-index: 2;
      position: relative;
      margin: auto;
    }

    picture {
      background-size: 190% !important;
      display: grid;
      padding: 1.25em;

      &:before, &:after {
        pointer-events: none;
        inset: 0;
        position: absolute;
      }

      &:before {
        content: '';
        backdrop-filter: blur(10px);
        background: rgba(black, 0.5);
      }
      
      &:after {
        content: url("data:image/svg+xml,%3Csvg width='68' height='47' viewBox='0 0 68 47' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M66.1 7.33913C65.7181 5.91637 64.9699 4.61914 63.9303 3.57734C62.8907 2.53554 61.5962 1.78573 60.1765 1.40301C54.9515 2.09173e-06 34 0 34 0C34 0 13.0485 2.09173e-06 7.82352 1.40301C6.40379 1.78574 5.10932 2.53554 4.06973 3.57734C3.03014 4.61914 2.28193 5.91637 1.90003 7.33913C0.499997 12.5753 0.5 23.5 0.5 23.5C0.5 23.5 0.499997 34.4247 1.90003 39.6609C2.28193 41.0836 3.03014 42.3809 4.06973 43.4227C5.10932 44.4645 6.40379 45.2143 7.82352 45.597C13.0485 47 34 47 34 47C34 47 54.9515 47 60.1765 45.597C61.5962 45.2143 62.8907 44.4645 63.9303 43.4227C64.9699 42.3809 65.7181 41.0836 66.1 39.6609C67.5 34.4247 67.5 23.5 67.5 23.5C67.5 23.5 67.5 12.5753 66.1 7.33913Z' fill='%23D81F1E'/%3E%3Cpath d='M27.5 33L44.5 23.0003L27.5 13V33Z' fill='white'/%3E%3C/svg%3E%0A");
        align-content: center;
        display: grid;
        justify-content: center;
        margin: auto;
        z-index: 2;
      }
    }
  }
}